import React from 'react';
import Bicycle from '../components/App/CaseStudies/Bicycle';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
export default function Page(props) {
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <meta charSet='utf-8' />
        <meta property='og:title' content='John Morales' />
        <meta name='author' content='John Morales' />
        <title>Don't Quit Bicycle Maintenance</title>
      </Helmet>
      <Bicycle />
    </div>
  );
}