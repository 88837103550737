import React from 'react';
import { BicycleContainer, BicycleWrapper, Box, Flex, Banner, Text, Title, Tag, CenteredText, LeftText } from './bicycle.style';
import BicycleScreens from '../../../../images/caseStudies/bicycle-screens.svg';
import TitleBanner from '../../../../images/caseStudies/title-banner.svg';
import GreenArrow from '../../../../images/caseStudies/green-arrow.svg';
import LaptopLeft from '../../../../images/caseStudies/bicycle-laptop-left.svg';
import LaptopRight from '../../../../images/caseStudies/bicycle-laptop-right.svg';
import Process from '../../../../images/caseStudies/process.svg';
import Observations from '../../../../images/caseStudies/observations.svg';
import SurveyData from '../../../../images/caseStudies/survey-data.svg';
import Define1 from '../../../../images/caseStudies/define-1.svg';
import Define2 from '../../../../images/caseStudies/define-2.svg';
import Define3 from '../../../../images/caseStudies/define-3.svg';
import Define4 from '../../../../images/caseStudies/define-4.svg';
import Ideate1 from '../../../../images/caseStudies/ideate-1.svg';
import Test1 from '../../../../images/caseStudies/test-1.svg';
import Test2 from '../../../../images/caseStudies/test-2.svg';
import Test3 from '../../../../images/caseStudies/test-3.svg';
import Prototype1 from '../../../../images/caseStudies/prototype-1.svg';
import Prototype2 from '../../../../images/caseStudies/prototype-2.svg';
import Prototype3 from '../../../../images/caseStudies/prototype-3.svg';
import Prototype4 from '../../../../images/caseStudies/prototype-4.svg';
import Prototype5 from '../../../../images/caseStudies/prototype-5.svg';
import Final1 from '../../../../images/caseStudies/final-1.gif';
import Final2 from '../../../../images/caseStudies/final-2.gif';
import Final3 from '../../../../images/caseStudies/final-3.gif';
import Final4 from '../../../../images/caseStudies/final-4.gif';
import RoomiezLink from '../../../../images/caseStudies/roomiez-link.svg';
import WebsiteLink from '../../../../images/caseStudies/website-link.svg';
import { Links, Link } from '../../Footer/footer.style';
import { navigate } from 'gatsby';
import Linkedin from '../../../../images/caseStudies/linkedin.svg';
import Envelope from '../../../../images/caseStudies/envelope.svg';

export default function Bicycle() {
    return (
        <BicycleContainer>
            <BicycleWrapper>
                <Title onClick={()=>navigate('/')}>John Morales</Title>
                <Box style={{ margin: '0 69px' }}>
                    <Box style={{ marginTop: '22.29px', textAlign: 'center' }}>
                        <img style={{ width: '80vw', marginLeft: '40px' }} src={BicycleScreens} loading="lazy"/>
                        <img style={{ width: '50vw', marginTop: '22px' }} src={TitleBanner} loading="lazy"/>
                    </Box>
                    <CenteredText style={{ margin: '91px 0 65px 0' }}>
                        <Text className='bold' style={{ marginBottom: '12px' }}>About the Project</Text>
                        <Text> At Devmountain program, I was a part of a team to design a website for the new non profit city-led bike servicing stations. This project was designed for novice cyclists wanting to become more comfortable maintaining and repairing their bike.</Text>
                    </CenteredText>
                    <LeftText>
                        <Text className='bold'>The Problem</Text>
                        <Text>Many bicycling enthusiasts struggle to find reliable and convenient resources online for bike maintenances. Traditional bike shops may have long wait times or limited hours, and several online resources can be overwhelming. Despite the increasing popularity of cycling and the growing number of city-led bike servicing stations, there is a lack of accessible and beginner-friendly platforms that cater specifically to individuals seeking to enhance their skills.</Text>
                    </LeftText>
                    <LeftText style={{ marginTop: '89px' }}>
                        <Text className='bold'>Objectives & Goals</Text>
                        <Text>
                            As part of a community effort to reduce carbon emissions and encourage alternate means of transportation, the city has announced bike servicing areas that will be made available to the public. At these facilities, bicyclists will be able to find stations and tools to repair and maintain their bikes, and experts who can help them by appointment. Not every station will have the same resources, so it is important that users can find what services are offered at each station and make an appointment if needed.
                        </Text>
                    </LeftText>
                    <Box style={{ marginTop: '87px', marginBottom: '116px', display: 'grid', justifyContent: 'center', fontSize: '20px' }}>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={GreenArrow} style={{ marginRight: '16px' }} /><Text>Provide a seamless booking experience with an expert.</Text></Flex>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={GreenArrow} style={{ marginRight: '16px' }} /><Text>Create a community of cycling enthusiasts through group meet up rides, fostering a sense of camaraderie among cyclists.</Text></Flex>
                        <Flex style={{ marginBottom: '50px' }}><img loading="lazy" src={GreenArrow} style={{ marginRight: '16px' }} /><Text>Empower cyclists to maintain their own bicycles through our do-it-yourself stations, providing them with the tools and guidance they need.</Text></Flex>
                        <Flex><img loading="lazy" src={GreenArrow} style={{ marginRight: '16px' }} /><Text>Offer maintenance classes to teach cyclists of all levels the skills they need to keep their bikes running smoothly.</Text></Flex>
                    </Box>
                    <Flex style={{ marginBottom: '111px' }}>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Role</Text>
                            <Text>UX Research</Text>
                            <Text>UI Design</Text>
                            <Text>Wireframing</Text>
                            <Text>Usability Testing</Text>
                            <Text>Prototyping</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Platform</Text>
                            <Text>Web app</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Timeline</Text>
                            <Text>June - July 2022</Text>
                        </LeftText>
                        <LeftText style={{ marginRight: 'auto' }}>
                            <Text className='bold-center'>Tools</Text>
                            <Text>Figma, FigJam</Text>
                        </LeftText>
                        <LeftText>
                            <Text className='bold-center'>Team</Text>
                            <Text>Akasha Stokes</Text>
                            <Text>Amariah Diamse </Text>
                            <Text>Katarina Winegar</Text>
                        </LeftText>
                    </Flex>
                    <LeftText style={{ marginBottom: '111px' }}>
                        <Text className='bold'>Concept</Text>
                        <Text>This web app gives users the ability to find DIY bike repair stations and easily schedule an appointment with an expert. They have the option to choose what service they're needing, the location, and the available times. As well, they can sign up for free bicycle maintenance classes and group meet up rides.</Text>
                    </LeftText>
                    <Flex style={{ justifyContent: 'center' }}>
                        <img loading="lazy" src={LaptopLeft} style={{ marginRight: '91px', width: '25vw' }} />
                        <img loading="lazy" src={LaptopRight} style={{ width: '25vw' }} />
                    </Flex>
                    <Box style={{ textAlign: 'center' }}>
                        <img loading="lazy" src={Process} style={{ width: '60vw' }} />
                    </Box>
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Empathize</Text></Banner>
            <BicycleWrapper>
                <LeftText>
                    <Text className='bold'>Quantitative Research</Text>
                    <Text>In order to develop a design that prioritizes the needs of the user, we initiated a process which involved the distribution of a survey aimed at collecting relevant data, conducting interviews with users and compiling research findings on bicycle maintenance. Our primary objective was to gauge the comfort level of individuals with their bicycles. This process resulted in 60 survey responses and 12 in-person interviews.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '105px', marginBottom: '110px'}}>
                    <img loading="lazy" src={SurveyData} style={{ width: '60vw' }} />
                </Box>
                <Box style={{textAlign: 'center', marginBottom: '100px'}}>
                    <img loading="lazy" src={Observations} style={{ width: '60vw' }} />
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Define</Text></Banner>
            <BicycleWrapper>
                <LeftText>
                    <Text className='bold'>User Persona Creation</Text>
                    <Text>After conducting in-person interviews and analyzing data such as the pains and gains list and empathy map, we utilized this information to construct a persona which we constantly referred to during the entire product development process. The board created for the persona included a range of details such as interests, influences, expectations, goals, motivations, and pain points, with particular attention given to the last three.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '75px'}}>
                <Box>
                <img loading="lazy" src={Define1} style={{ width: '60vw', marginBottom: '120px' }} />
                </Box>
                <Box>
                <img loading="lazy" src={Define2} style={{ width: '60vw', marginBottom: '120px'}} />
                </Box>
                <Box>
                <img loading="lazy" src={Define3} style={{ width: '60vw', marginBottom: '120px' }} />
                </Box>
                <Box>
                <img loading="lazy" src={Define4} style={{ width: '60vw' }} />
                </Box>
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Ideate</Text></Banner>
            <BicycleWrapper>
                <LeftText>
                    <Text className='bold'>Sketches</Text>
                    <Text>Each team member individually began brainstorming and sketching wireframes, while keeping in mind the project's primary goal of providing bicyclists access to repair stations in their city. We considered our users' objectives and motivations during the initial sketching phase. With the help of a site-map, each team member independently sketched what they believed to be critical for each page. We then presented and explained our designs to each other. After reviewing the similarities and differences among the sketches, we decided upon the elements that should be incorporated into the final low fidelity wireframe. Following this, each team member sketched a new low fidelity design that included the previously agreed-upon elements.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '85px', marginBottom: '85px'}}>
                    <img loading="lazy" src={Ideate1} style={{ width: '60vw' }} />
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Prototype</Text></Banner>
            <BicycleWrapper>
                <CenteredText>
                    <Text className='bold'>Wireframes & Low-Fidelity Prototype</Text>
                    <Text>Upon the completion of the final sketch layout, the next step was to integrate the ideas into wireframes. In order to obtain a clear visualization of the websites  structure, low-fidelity wireframes for the primary pages were produced using Figma.</Text>
                </CenteredText>
                <Box style={{textAlign: 'center', width: 'fit-content', margin: '90px auto 79px auto'}}>
                    <img loading="lazy" src={Prototype1} style={{ width: '60vw' }} />
                    <Box style={{margin: 'auto', textAlign: 'left'}}>
                        <Text style={{fontWeight:'bold', fontSize:'20px', color: '#0D9488'}}>Homepage:</Text>
                        <Text style={{fontSize:'20px'}}>
                        • Landing Page / Simplicity
                        <br/>
                        • Search bar / Tabs for every objective
                        <br/>
                        • Information & Images per section
                        </Text>
                    </Box>
                </Box>
                <Flex style={{textAlign: 'center', justifyContent: 'space-between', alignItems:' center', marginBottom: '200px'}}>
                    <Box style={{ width: '200px', height: '475px' }}>
                        <img loading="lazy" src={Prototype2} />
                        <Box style={{width: 'fit-content', margin: 'auto', textAlign: 'left'}}>
                        <Text style={{fontWeight:'bold', fontSize:'20px', color: '#0D9488'}}>Event page:</Text>
                        <Text style={{fontSize:'20px'}}>
                        • Lists of upcoming group rides
                        <br/>
                        • Lists of upcoming maintenance classes
                        </Text>
                    </Box>
                    </Box>
                    <Box style={{ width: '200px', height: '475px' }}>
                        <img loading="lazy" src={Prototype3} />
                        <Box style={{width: 'fit-content', margin: 'auto', textAlign: 'left'}}>
                        <Text style={{fontWeight:'bold', fontSize:'20px', color: '#0D9488'}}>Scheduling flow:</Text>
                        <Text style={{fontSize:'20px'}}>
                        • Calendar
                        <br/>
                        • Image of a bicycle is presented with interactive functionality
                        </Text>
                    </Box>
                    </Box>
                    <Box style={{ width: '200px', height: '475px' }}>
                        <img loading="lazy" src={Prototype4}/>
                        <Box style={{width: 'fit-content', margin: 'auto', textAlign: 'left'}}>
                        <Text style={{fontWeight:'bold', fontSize:'20px', color: '#0D9488'}}>Stations page:</Text>
                        <Text style={{fontSize:'20px'}}>
                        • Map
                        <br/>
                        • List of locations & DIY stations
                        </Text>
                    </Box>
                    </Box>
                    <Box style={{ width: '200px', height: '475px' }}>
                        <img loading="lazy" src={Prototype5} />
                        <Box style={{width: 'fit-content', margin: 'auto', textAlign: 'left'}}>
                        <Text style={{fontWeight:'bold', fontSize:'20px', color: '#0D9488'}}>About Us page:</Text>
                        <Text style={{fontSize:'20px'}}>
                        • Mission statement
                        <br/>
                        • An option to donate
                        <br/>
                        • Locations
                        </Text>
                    </Box>
                    </Box>
                </Flex>
            </BicycleWrapper>
            <Banner><Text className='text'>Test</Text></Banner>
            <BicycleWrapper>
                <LeftText>
                    <Text className='bold'>User Testing</Text>
                    <Text>Prior to generating high-fidelity wireframes, we conducted a preliminary phase of usability testing employing our low-fidelity prototype. In order to assess the functionality of the prototype, we established a set of eight specific tasks that we administered to our test participants. We collectively reviewed all feedback obtained, and deliberated on strategies to remedy specific pain points.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '110px'}}>
                    <Box>
                        <img loading="lazy" src={Test1} style={{ width: '60vw', marginBottom: '110px' }} />
                    </Box>
                    <Box>
                        <img loading="lazy" src={Test2} style={{ width: '60vw' }} />
                    </Box>
                </Box>
                <LeftText style={{marginTop: '110px'}}>
                    <Text className='bold'>User Testing - Round 2</Text>
                    <Text>Upon finalizing our task list, we proceeded to conduct another round of testing, utilizing the same cohort of test subjects. Following the completion of tasks, our team reconvened to review the findings. It was gratifying to note that the majority of the issues experienced by the participants during the first prototype had been <strong>successfully addressed</strong>. The feedback pertaining to the second prototype primarily focused on <strong>cosmetic improvements</strong> instead of significant concerns with the <strong>user-flow</strong>.</Text>
                </LeftText>
                <Box style={{textAlign: 'center', marginTop: '90px', marginBottom :'100px'}}>
                    <img loading="lazy" src={Test3} style={{ width: '60vw' }} />
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Final Design</Text></Banner>
            <BicycleWrapper>
                <LeftText>
                    <Text className='bold'>High Fidelity Wireframe</Text>
                    <Text>Following the completion of the second round of usability testing, we were prepared to commence the development of our high fidelity wireframes. Prior to beginning, we deemed it necessary to establish a design system. We came to a consensus on the layout, typography, color scheme, images and graphics, interactivity, and accessibility.</Text>
                </LeftText>
                <Box style={{width: '745px', margin: '110px auto 70px auto'}}>
                    <Text style={{textAlign: 'left'}}>The purpose of this website empowers users to locate DIY bicycle repair stations and conveniently book appointments with proficient technicians. Users are presented with options to specify the required services, preferred location, and available time slots. Additionally, they can enroll in complimentary bicycle maintenance classes and participate in organized group cycling sessions.</Text>
                </Box>
                <Text style={{fontSize:"28px", textAlign: "center"}}>Landing / Homepage / Profile</Text>
                <Box style={{textAlign:'center', marginBottom:'150px'}}>
                    <img loading="lazy" src={Final1} style={{ width: '60vw' }} />
                </Box>
                <Text style={{fontSize:"28px", textAlign: "center"}}>Scheduling / Locating DIY Stations</Text>
                <Box style={{textAlign:'center', marginBottom:'150px'}}>
                    <img loading="lazy" src={Final2} style={{ width: '60vw' }} />
                </Box>
                <Text style={{fontSize:"28px", textAlign: "center"}}>Signing up for meet-ups & classes</Text>
                <Box style={{textAlign:'center', marginBottom:'150px'}}>
                    <img loading="lazy" src={Final3} style={{ width: '60vw' }} />
                </Box>
                <Text style={{fontSize:"28px", textAlign: "center"}}>About us & Donations</Text>
                <Box style={{textAlign:'center', marginBottom:'150px'}}>
                    <img loading="lazy" src={Final4} style={{ width: '60vw' }} />
                </Box>
            </BicycleWrapper>
            <Banner><Text className='text'>Wrapping Up</Text></Banner>
            <BicycleWrapper>
            <LeftText>
                <Text className='bold'>Takeaways</Text>
                <Text>Through my involvement in this project, I gained valuable insights into the significance of usability testing and the iterative design process. I recognized the importance of continuously revisiting and refining our designs based on user feedback and needs, regardless of the number of iterations required. I learned the power of minimalism in interface design, prioritizing a streamlined and focused approach over overwhelming users with excess information. These experiences have greatly influenced my user-centric and simplified approach to UX/UI design, creating impactful digital experiences.</Text>
            </LeftText>
            <LeftText style={{marginTop:'110px'}}>
                <Text className='bold'>Next Steps</Text>
                <Text>When it comes to improving this experience some <strong>next steps and goals</strong> for this product would be:</Text>
                <br/>
                <Text style={{marginLeft:'20px'}}>• Enable the checkout process to accommodate multiple repairs within a single transaction, allowing users to conveniently select and pay for multiple services at once.</Text>
                <br/>
                <Text style={{marginLeft:'20px'}}>• Develop and design a dedicated Volunteer page that provides information and opportunities for individuals interested in contributing to their community.</Text>
                <br/>
                <Text style={{marginLeft:'20px'}}>• Refine the design and functionality of the "Events" banner on the homepage and implement interactive features that encourage users to explore and participate in relevant events.</Text>
            </LeftText>
            </BicycleWrapper>
            <BicycleWrapper>
                <Text style={{textAlign:'center', fontSize:'36px', marginBottom:'50px', fontFamily: "Sansita" }}>See more of my work</Text>
                <Box style={{textAlign :'center'}}>
                    <img onClick={()=>navigate('/roomiez')} src={RoomiezLink} style={{ width: '290px', marginRight:'25px', cursor: 'pointer' }} />
                    <img onClick={()=>navigate('https://www.figma.com/file/9ZCZV5CSuT4JvOZ7vgI7iy/My-website?type=design&node-id=781%3A7289&mode=design&t=cratq7L1vDsqrnmM-1')} src={WebsiteLink} style={{ width: '290px',  cursor: 'pointer' }} />
                </Box>
                <Box style={{border:'1px solid black', marginTop:'11px', marginBottom:'50px'}}></Box>
                <Text style={{textAlign:'center', fontSize:'64px', fontFamily: "Sansita", fontWeight: 700}}>Let's Connect</Text>
                <br/>
                <Links>
                <Link href="https://drive.google.com/file/d/1TAsG1GGcv9ny1YugdZVkOB3nxBe6VgZB/view" target="_blank" style={{color:'black', marginLeft: "0px"}}>
                  Resume
                </Link>
                <Link href='mailto:jay_morales0@yahoo.com' target="_blank" style={{color:'black', display: "flex"}}>
                <img loading="lazy" src={Envelope}  style={{ marginRight: "10px"}}/>
                  Mail
                </Link>
                <Link href='https://www.linkedin.com/in/jay-morales' target="_blank" style={{color:'black', display: "flex"}}>
                <img loading="lazy" src={Linkedin} style={{ marginRight: "10px"}}/>
                  Linkedin
                </Link>
              </Links>
            </BicycleWrapper>
        </BicycleContainer>
    );
}
